import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SubscriptionPlan } from "../lib/types/all";
import { calculatePaymentText } from "../lib/util/helper";

export interface ISmallPrint {
  plan?: SubscriptionPlan;
  orgName: string | null;
}

export const SmallPrint: React.FC<ISmallPrint> = ({ plan, orgName }) => {
  return (
    <div>
      <Typography style={{ fontSize: 14, marginTop: 8 }}>{calculatePaymentText(plan,orgName)}</Typography>
      <Typography style={{ fontSize: 14, marginTop: 8 }}>
       For more details please refer to our{" "}
        <a
          target="_blank"
          href="https://www.easybusinessapp.com/privacy-policy"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a target="_blank" href="https://www.easybusinessapp.com/terms-of-use">
          Terms of Use
        </a>
        .
      </Typography>
    </div>
  );
};
