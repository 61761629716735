import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SubscribePage from "./SubscribePage";
import { ThemeProvider } from "@mui/system";
import { createTheme, ThemeOptions } from "@mui/material";
import { theme } from "./lib/theme/theme";
import { AppRegistration } from "@mui/icons-material";

const stripePromise = loadStripe("pk_live_a2e6WczzNfS653VzcIjTioWA");

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <SubscribePage />
      </Elements>
    </ThemeProvider>
  );
}
