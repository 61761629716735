import { toLower, includes } from "lodash";
import { Coupon, SubscriptionPlan } from "../types/all";

type DeviceType = "mobile" | "tablet" | "desktop" | "";
type DeviceOS = "macos" | "windows" | "android" | "ios" | "";
type DeviceBrowser =
  | "Chrome"
  | "Safari"
  | "Firefox"
  | "Opera"
  | "IE"
  | "Edge"
  | "";

interface UserDeviceType {
  reactNativePlatform: "web";
  deviceType: DeviceType;
  browserName?: DeviceBrowser;
  deviceOSName?: DeviceOS;
  combinedDevice: string;
}

// getUserDeviceType returns an object containing device type properties, which are sourced from react-native-device-info on Android/iOS
// and from some mildly dubious user agent string parsing for web.
// This function should ONLY BE USED for analytics purposes - we should never make display/feature-type conditional on this return.
export const getUserDeviceType = (): UserDeviceType => {
  const platform = "web";
  let deviceType = "" as DeviceType;
  let deviceOSName = "" as DeviceOS;
  let deviceBrowser = "" as DeviceBrowser;

  const uaString = navigator.userAgent;
  deviceOSName = guessDeviceOSFromUserAgent(uaString);
  deviceBrowser = detectBrowser();

  //examples of combinedDevice:
  // 'ios-mobile' <=== iPhone App User
  // 'ios-tablet' <=== iPad App User
  // 'android-mobile' <=== Android phone App User
  // 'android-tablet' <=== Android tablet App User
  // 'web-macos-safari' <=== desktop Safari user on MacOS
  // 'web-macos-chrome' <=== desktop Chrome user on MacOS
  // 'web-ios-safari' <=== mobile/tablet Safari user on iOS
  // 'web-windows-chrome' <=== desktop Chrome user on Windows
  // 'web-windows-firefox' <=== desktop Firefox user on Windows
  // 'web-android-chrome' <=== mobile/tablet Chrome user on Android
  const combinedDeviceString =
    platform +
    (deviceType ? `-${deviceType}` : "") +
    (deviceOSName ? `-${deviceOSName}` : "") +
    (deviceBrowser ? `-${toLower(deviceBrowser)}` : "");

  return {
    reactNativePlatform: platform,
    deviceType: deviceType,
    deviceOSName: deviceOSName,
    browserName: deviceBrowser,
    combinedDevice: combinedDeviceString,
  };
};

// This is guessing the type of the OS from the user agent
// It's not 100% accurate, but it's better than nothing
export const guessDeviceOSFromUserAgent = (userAgent: string): DeviceOS => {
  if (includes(userAgent, "iPhone")) {
    return "ios";
  }
  if (includes(userAgent, "iPad")) {
    return "ios";
  }
  if (includes(userAgent, "Android")) {
    return "android";
  }
  if (includes(userAgent, "Windows")) {
    return "windows";
  }
  if (includes(userAgent, "Macintosh")) {
    return "macos";
  }
  //let's just go with a blank string return here if we don't know
  return "";
};

//gets the type of browser
export const detectBrowser = () => {
  if (
    includes(navigator.userAgent, "Opera") ||
    includes(navigator.userAgent, "OPR")
  ) {
    return "Opera";
  } else if (includes(navigator.userAgent, "Chrome")) {
    return "Chrome";
  } else if (includes(navigator.userAgent, "Safari")) {
    return "Safari";
  } else if (includes(navigator.userAgent, "Firefox")) {
    return "Firefox";
  } else if (includes(navigator.userAgent, "MSIE")) {
    return "IE";
  } else if (includes(navigator.userAgent, "Edge")) {
    return "Edge";
  } else {
    return "";
  }
};


export const generateCouponString = (coupon: Coupon): string => {
  switch (coupon.duration) {
    case 'forever':
      return `${coupon.percentOff}% off forever`;
    case 'once':
      return `${coupon.percentOff}% off your first month`;
    case 'repeating':
      return `${coupon.percentOff}% off for ${coupon.durationMonths || 1} months`;
    default:
      //should be impossible
      return 'Invalid coupon';
  }
};

export const calculatePaymentText = (
  plan: SubscriptionPlan | undefined,
  orgName: string | undefined
) => {
  let pricingText = '';
  const postText = `This card will be used as the billing card for all of your subscriptions for ${
    orgName || 'this organisation'
  } moving forwards. There is no minimum term and you may cancel the monthly renewal at any time.`;
  // the line above should end on a hanging 'or ' as we need to add links afterwards.
  if (plan) {
    if (plan.finalAmount) {
      if (plan.finalAmount != plan.amount) {
        //there is a coupon here
        pricingText = `Your payment method above will be charged $${plan.finalAmount} per month until your coupon expires as indicated above, then you will be charged the standard plan price until you cancel.`;
      } else {
        pricingText = `Your payment method above will be charged $${plan.amount} per month until you cancel.`;
      }
    } else {
      pricingText = `Your payment method above will be charged $${plan.amount} per month until you cancel.`;
    }
  } else {
    pricingText = `Your payment method above will be charged until you cancel.`;
  }
  return `${pricingText} ${postText}`;
};