import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { some, includes, toUpper } from "lodash";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { default as Grid } from "@mui/material/Unstable_Grid2"; // Grid version 2
import LoadingButton from "@mui/lab/LoadingButton";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Module, SubscriptionPlan } from "./lib/types/all";
import { SubscriptionCard } from "./components/SubscriptionCard";
import { FAQAccordion } from "./components/FAQAccordion";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import { SmallPrint } from "./components/SmallPrint";
import { DUMMY_DATA } from "./lib/data/dummy";

import EBALogo from "./lib/images/eba-logo.png";
import EPSLogo from "./lib/images/eps-logo.png";
import EINLogo from "./lib/images/ein-logo.png";
import BANLogo from "./lib/images/ban-logo.png";
import DoneIcon from "./lib/images/done.png";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ErrorRounded } from "@mui/icons-material";
import { generateCouponString } from "./lib/util/helper";

const DEV_API = "https://api-dev.test.easybusinessapps.com.au";
const QA_API = "https://api-qa.test.easybusinessapps.com.au";
const PROD_API = "https://api.easybusinessapps.com.au";

let apiUrl = '';

switch(window.location.hostname) {
  case 'subscribe.easybusinessapp.com':
    apiUrl = PROD_API;
    break;
  case 'subscribe-qa.easybusinessapp.com':
    apiUrl = QA_API;
    break;
  case 'subsribe-dev.easybusinessapp.com':
  default:
    apiUrl = DEV_API;
    break;
}


//don't log Sentry errors when running in local dev environment
const localValues = ["localhost", "0.0.0.0"];
const isLocalhost = some(localValues, (lValue) =>
  includes(window.location.hostname, lValue)
);

const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Roboto, sans-serif",
      fontSmoothing: "antialiased",
      backgroundColor: "white",
      fontSize: "17px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  hidePostalCode: true,
};

function apiPricingPlans(orgId,module, coupon?) {
  return axios.get(
    apiUrl +
      `/api/v1/qs/orgs/${orgId}/modules/${module}/platforms/stripe/plans?coupon=${
        coupon ? coupon : ""
      }`
  );
}

function apiCreatePaymentMethod(orgId, token) {
  return axios.post(
    apiUrl + `/api/v1/qs/orgs/${orgId}/subscriptions/stripe/payment-methods`,
    { token: token }
  );
}

function apiCreateSubscription(orgId, planId, paymentMethod, coupon) {
  return axios.post(apiUrl + `/api/v1/qs/orgs/${orgId}/subscriptions/stripe`, {
    planID: planId,
    paymentMethod: paymentMethod,
    voucher: coupon,
  });
}

export default function SubscribePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [modalError, setModalError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [plan, setPlan] = useState<SubscriptionPlan>();
  const [pricingPlans, setPricingPlans] = useState([] as SubscriptionPlan[]);

  const searchParams = new URLSearchParams(window.location.search);
  const module = searchParams.get("module") as Module; //mod_easypayslip, mod_easyinvoicing, mod_banking
  const orgId = searchParams.get("orgId");
  let orgName = searchParams.get("orgName");
  const coupon = toUpper(searchParams.get("coupon"));

  //remove org ID which could be passed in format "Org Name (org_xxxxxx)"
  //HubSpot can't currently rewrite these, and we don't have the org name available in any other field
  const orgIdPattern = /\(org_[^\)]+\)/;
  if(orgName) {
    orgName = orgName.replace(orgIdPattern, '');
  }

  const stripe = useStripe();
  const elements = useElements();

  const theme = useTheme();
  const dialogFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    async function getData() {
      try {
        const subsResponse = await apiPricingPlans(orgId,module,coupon);
        setPricingPlans(subsResponse.data);
        setError("");
      } catch (err) {
        !isLocalhost && Sentry.captureException(err);
        if (err.response?.data?.message) {
          setError(err.response.data.message);
        } else {
          setError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();

    // This allows some dummy data to be loaded purely for testing purposes
    // setPricingPlans(DUMMY_DATA);
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 2000);
  }, [orgId]);

  const openModal = async (plan: SubscriptionPlan) => {
    setPlan(plan);
    setShowModal(true);
  };

  const handlePayment = async (e) => {
    setIsPurchasing(true);
    setModalError("");
    e.preventDefault();
    try {
      const cardElement = elements?.getElement(CardElement);
      if (!cardElement) {
        !isLocalhost && Sentry.captureException("No cardElement was loaded.");
        setModalError(
          "Error loading payment library. Please try again. You have not been charged."
        );
        return;
      }
      const result = await stripe?.createToken(cardElement);
      if (!result) {
        !isLocalhost &&
          Sentry.captureException("No result from stripe.createToken.");
        setModalError(
          "Error creting payment token. Please try again. You have not been charged."
        );
        return;
      }

      const { error, token } = result;
      if (error) {
        !isLocalhost && Sentry.captureException(error);
        setModalError(error.message);
        return;
      }
      // console.log(token);
      const paymentMethodResp = await apiCreatePaymentMethod(orgId, token.id);
      // console.log(paymentMethodResp);
      const subscribeResp = await apiCreateSubscription(
        orgId,
        plan?.id,
        paymentMethodResp.data.id,
        coupon
      );
      // console.log(subscribeResp);

      setSuccess(`You have subscribed to ${plan?.moduleName}!`);
      setShowModal(false);
    } catch (err) {
      !isLocalhost && Sentry.captureException(err);
      //if we have an AXIOS error with some additional error details, show that error
      if(err?.response?.data?.message) {
        setModalError(err?.response?.data?.message);
      } else {
        setModalError(err.message);
      }
    } finally {
      setIsPurchasing(false);
    }
  };

  let moduleLogo = EBALogo;
  switch(module) {
    case 'mod_easypayslip':
      moduleLogo = EPSLogo;
      break;
    case 'mod_easyinvoicing':
      moduleLogo = EINLogo;
      break;
    case 'mod_easybanking':
      moduleLogo = BANLogo;
      break;
    default:
      moduleLogo = EBALogo;
  }

  let incorrectParameters = false;
  if(module != 'mod_easypayslip' && module != 'mod_easyinvoicing' && module != 'mod_easybanking') {
    incorrectParameters = true;
  }

  if(!orgId)  {
    incorrectParameters = true;
  }

  return (
    <>
    {!incorrectParameters ?
    (
      <Container sx={styles.container}>
        <div style={{ textAlign: "center" }}>
          <a
            href="https://www.easybusinessapp.com/payroll"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={moduleLogo}
              style={{ maxWidth: 140 }}
              alt={"Easy Business App logo"}
            />
          </a>
        </div>
        {success ? (
          <Box py={4} textAlign="center">
            <img src={DoneIcon} style={{ maxWidth: 200 }} alt="Success icon" />
            <Typography
              textAlign={"center"}
              variant={"h4"}
              sx={{ color: "green", py: 2 }}
            >
              {success}
            </Typography>
            <Typography>
              You can use the app on your Android or iOS device, or{" "}
              <a href="https://app.easypayslip.com">login on web</a>.
            </Typography>
          </Box>
        ) : (
          <>
            <Typography variant="h4" p={1} textAlign="center" fontWeight={700}>
              Available Plans
            </Typography>
            <Typography variant="h5" p={1} textAlign="center">
              {orgName}
            </Typography>
            {coupon && (
              <Typography
                variant="h6"
                sx={{ color: "green", textAlign: "center", my: 1 }}
              >
                Coupon <strong>{coupon.toUpperCase()}</strong> applied!
              </Typography>
            )}

            {isLoading && (
              <Box sx={{ display: "flex", justifyContent: "center", p: 5 }}>
                <CircularProgress />
              </Box>
            )}
            {!!error && (
              <Box textAlign={"center"} my={4}>
                <ErrorRounded color={"error"} fontSize={"large"} />
                <Typography
                  sx={{ color: "error.main", textAlign: "center", mt: 1 }}
                  fontWeight={700}
                >
                  Error: {error}
                </Typography>
              </Box>
            )}
            {!isLoading && !!pricingPlans && !error && (
              <>
                <Grid container spacing={2}>
                  {pricingPlans.map((plan) => (
                    <Grid key={plan.id} xs={12} md={12 / pricingPlans.length}>
                      <SubscriptionCard plan={plan} openModal={openModal} />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Grid sx={{ maxWidth: 800 }}>
                <Typography py={2} textAlign={"center"} variant="h5">
                  FAQs
                </Typography>
                <FAQAccordion module={module} />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    ) : <Container sx={styles.container}>
      <div style={{ textAlign: "center" }}><Typography py={1} variant={"h5"}>Error loading page - missing parameters.</Typography></div>
      </Container>}
      <div style={{ textAlign: "center" }}>
        <a
          href="https://www.easybusinessapp.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={EBALogo}
            style={{ maxWidth: 200, paddingTop: 32 }}
            alt={"Easy Business App logo"}
          />
        </a>
        <Typography py={1} variant={"subtitle2"}>
          &copy; Easy Business App {new Date().getFullYear()}
        </Typography>
      </div>

      {/********** MODAL DIALOGS **********/}
      {plan && (
      <Dialog
        open={showModal}
        fullScreen={dialogFullScreen}
        onClose={() => setShowModal(false)}
      >
        <DialogTitle sx={{ backgroundColor: "#efefef" }}>
          New {plan?.moduleName} Subscription
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#efefef" }}>
          <Typography color={"primary"} style={{marginBottom: 0, fontSize: 18, textAlign: 'center', fontWeight: 'bold'}}>
              {plan?.planName} - ${plan?.finalAmount}/month
          </Typography>
          {plan.coupon && (
            <Typography style={{marginBottom: 8, fontStyle: 'italic', textAlign: 'center'}}>
              {generateCouponString(plan?.coupon)}, then ${plan?.amount}/month.
            </Typography>
          )}
          <Typography style={{marginTop: 8}}>
            Enter your card details to start a subscription for{" "}
            <strong>{orgName}</strong>.
          </Typography>
          <Box
            sx={{
              mt: 2,
              py: 0,
            }}
          >
            <form onSubmit={handlePayment}>
              <div style={styles.cardContainer}>
                <CardElement
                  id="card-element"
                  options={CARD_ELEMENT_OPTIONS}
                  onChange={() => setModalError("")}
                />
              </div>
              {!!modalError && (
                <Typography sx={{ color: "error.main", my: 1 }}>
                  {modalError}
                </Typography>
              )}
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ mt: 2, width: "100%" }}
                disabled={!stripe || !elements}
                loading={isPurchasing}
              >
                Subscribe
              </LoadingButton>
              {/* <Button
                variant="contained"
                type="submit"
                sx={{ mt: 2, width: "100%" }}
                disabled={!stripe || !elements}
              >
                Subscribe
              </Button> */}
              <Button
                variant="text"
                sx={{
                  mt: 1,
                  width: "100%",
                  color: "gray",
                  borderColor: "gray",
                }}
                onClick={() => setShowModal(false)}
                disabled={isPurchasing}
              >
                Cancel
              </Button>
            </form>
          </Box>
          <SmallPrint orgName={orgName} plan={plan} />
        </DialogContent>
      </Dialog>
      )}

    </>
  );
}

const styles = {
  container: {
    backgroundColor: "primary.light",
    height: "100%",
    px: 2,
    py: 3,
    pb: 5,
  },
  cardContainer: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
    backgroundColor: "white",
  },
};
