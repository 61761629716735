import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Module } from "../lib/types/all";
import { filter} from 'lodash';

const ALL_FAQS: FAQ[] = [
  {
    question: "Do all plans have the same features?",
    answer:
      "Yes. The only difference is the number of active employees you can set up.",
    module: 'mod_easypayslip'
  },
  {
    question: "Are all features available during the 30 day free trial?",
    answer:
      "Yes, all features are available. Set up your employees, send your payslips, even send STP files to the ATO.",
    module: 'mod_easypayslip'
  },
  {
    question:
      "What happens when my free trial ends, can I still use the app without a subscription?",
    answer: `Yes. You can still use the app to view your existing payslips and STP; create and edit employees; run reports; submit STP and preview (but not save and send) payslips. To create new payslips an active subscription is required.`,
    module: 'mod_easypayslip'
  },
  {
    question: "What happens if my company grows, can I change my plan?",
    answer:
      "Yes. You can change your plan at any time in the app, either up or down and there is no minimum commitment.",
      module: 'all'
  },
  {
    question: "Can I use Easy Business App on more than one device?",
    answer:
      "Yes. You can install the app on any iOS or Android device, as well as using our web app on your computer through a browser. All your data will synchronize between your devices.",
      module: 'all'
  },
  {
    question:
      "What if I need to share my account, for example with my accountant?",
    answer:
      "You can easily share access to your Easy Payslip account with your accountant. You can manage who can access your account in the My Organisation screen. Tap the Add Member button to invite new members.",
      module: 'all'
  },
];

interface FAQ {
  question: string;
  answer: string;
  module: Module | 'all';
}

export interface IFAQAccordion {
  module: Module;
}

export const FAQAccordion: React.FC<IFAQAccordion> = ({module}) => {
  const FAQs = filter(ALL_FAQS, (i:FAQ) => i.module == module || i.module == 'all' )
  return (
    <div>
      {FAQs && FAQs.map((faq, index) => (
        <Accordion key={faq.question}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={"panel" + index + 1 + "header"}
          >
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={"bold"}>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
