import { createTheme, ThemeOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    payroll: {
      main: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    payroll?: {
      main?: string;
    };
  }
}

export const themeOptions: ThemeOptions = {
  payroll: {
    main: "#fd5739",
  },
  palette: {
    //type: 'light',
    primary: {
      light: "#f2f2f2",
      main: "#005cff",
    },
    secondary: {
      main: "#fd5739",
    },
  },
};

export const theme = createTheme(themeOptions);
